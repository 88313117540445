import React from "react"
import PropTypes from "prop-types"
import "./feature.scss"

const Feature = ({
  image,
  title,
  description,
  className,
  horizontalOnMobile,
}) => {
  return (
    <div
      className={`feature ${className} ${
        horizontalOnMobile ? "horizontal" : ""
      }`}
    >
      <div
        className="feature-image"
        style={{ backgroundImage: `url('${image}')` }}
      ></div>
      <div className="info">
        {title && <h3 className="title">{title}</h3>}
        <p className="description">{description}</p>
      </div>
    </div>
  )
}

Feature.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
}

Feature.defaultProps = {
  title: ``,
}
export default Feature
