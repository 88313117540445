import React from "react"
import project11 from "./images/project-1-1.jpg"
import project21 from "./images/project-2-1.jpg"
import project22 from "./images/project-2-2.jpg"
import project23 from "./images/project-2-3.jpg"
import project24 from "./images/project-2-4.jpg"
import project25 from "./images/project-2-5.jpg"
import project31 from "./images/project-3-1.jpg"
import project32 from "./images/project-3-2.jpg"
import project41 from "./images/project-4-1.jpg"
import project42 from "./images/project-4-2.jpg"
import project43 from "./images/project-4-3.jpg"
import project51 from "./images/project-5-1.jpg"
import project61 from "./images/MaryleboneResidences.jpeg"

export const PROJECTS = [
  {
    title: "Devonshire Mansions",
    subtitle: "14 Devonshire Place",
    description:
      "This Marylebone four-storey townhouse comprises a collection of residential apartments and office space and a large patio area roof terrace, enjoying breath-taking views of the iconic London skyline. The apartments are just moments from Regent's Park and its famous English Gardens which are an ideal place for a run or meeting spot to see friends. It is also moments from the world famous private practices of Harley Street and the Royal Academy of Music.",
    gallery: [project11],
  },
  {
    title: "Queen's Gate Terrace",
    subtitle: "3-7 Queen's Gate Terrace",
    description:
      "This classical property is located in South Kensington, within walking distance to Hyde Park and next to one of the most ambitious and highly-rated universities worldwide, Imperial College London. Comprising a vast 22,000 sq ft in scale, the property consists of classrooms and offices, providing high-quality accommodation for academics and students. With its PC rooms, common rooms, laundry room and lift, the property has been tailored for the 21st century's next generations of bright minds.",
    gallery: [project21, project22, project23, project24, project25],
  },
  // {
  //   title: "Palace Court Manors",
  //   subtitle: "47 Palace Court",
  //   description: (
  //     <>
  //       <p>
  //         This distinctive former Queen Anne-style terrace development has an
  //         unrivalled position, less than 300 metres to the Royal Parks of Hyde
  //         Park and Kensington Gardens and 400 metres to Notting Hill and the
  //         prestigious shopping street of Westbourne Grove. The property benefits
  //         from excellent transport links around London, including Notting Hill
  //         Gate, Bayswater and Queensway underground stations, which are just a
  //         short five-minute walk away.
  //       </p>
  //       <p>
  //         This Grade II listed building has a fascinating literary and blue
  //         plaque heritage, originally built in 1889 for Wilfrid and Alice
  //         Meynell (Thompson) by Leonard Aloysius Scott Stokes, a famous English
  //         architect. Adorning the front of the property is one of London's
  //         famous blue plaques - one of over 950 across the capital - that was
  //         erected in 1948 in memory of Alice.
  //       </p>
  //     </>
  //   ),
  //   gallery: [project31, project32],
  // },
  {
    title: "Ventures Hotel",
    subtitle: "12-20 Sussex Gardens",
    description: (
      <>
        <p>
          Ventures Hotel in Paddington on 12-20 Sussex Gardens is our first
          multi-million pound hospitality asset investment in Prime Central
          London, W2. The acquisition marks an important move into the
          Hospitality Sector and our plans to invest and develop in the
          infrastructure of W2, beyond residential property.
        </p>
        <p>
          The hotel consists of five buildings with 78 rooms and spans circa
          20,000 sq. ft. Building upon our growing Hyde Park area portfolio
          overlooking London's finest garden squares nearby to green spaces; the
          hotel is ideally located on the doorstep of Hyde Park Square, a short
          stroll from Hyde Park and the picturesque canals of Little Venice and
          beautiful lawns of Regent's Park. The hotel also enjoys superb retail
          and transport links, with the world-famous shops of Oxford Circus,
          Edgware Road Tube Station and Paddington Station all just moments
          away.
        </p>
      </>
    ),
    gallery: [project41, project42, project43],
  },
  {
    title: "Kensington Park Corner",
    subtitle: "305-317 Westbourne Park Road & Kensington Park Road",
    description: (
      <>
        <p>
          Located off the fashionable Portobello Road in Notting Hill,
          Westbourne Park Place comprises mixed use commercial and residential
          buildings. It consists of a substantial amount of fronted shop units
          on Westbourne Park Road which is ideally located for the restaurants,
          shops and bars of Portobello.
        </p>
        <p>
          The nearest stations are Notting Hill Station with four tube lines
          available, Westbourne Park and Ladbroke Grove Station. It is also
          located right opposite the famous Blue Door from the renowned movie
          Notting Hill.
        </p>
      </>
    ),
    gallery: [project51],
  },
  {
    title: "Marylebone Residences",
    subtitle: "45 Crawford Street",
    description: (
      <>
        <p>
          Arranged over five floors in the fashionable neighbourhood of
          Marylebone, Marylebone Residences is a freehold mixed-use building
          including retail space, as well as three self-contained one-bedroom
          apartments.
        </p>
        <p>
          Located equidistant from two of London's prestigious Royal Parks, the
          property provides easy access to Regent's Park and Hyde Park with over
          740 acres of open green space. There are also excellent local
          transport links around London, with five tube lines servicing Baker
          Street Station a 9-minute walk away.
        </p>
      </>
    ),
    gallery: [project61],
  },
]
