import React from "react"
import "./service-block.scss"

const ServiceBlock = ({ children, bgColor, bgImage }) => {
  return (
    <div
      className="service-container"
      style={
        bgImage
          ? { backgroundImage: `url('${bgImage}')` }
          : { backgroundColor: bgColor }
      }
    >
      <div className="service-content">{children}</div>

      <div className="overlay"></div>
    </div>
  )
}

export default ServiceBlock
