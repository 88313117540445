import React, { useRef } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles/index.scss"
import Feature from "../components/Feature"
import DisruptingInvestmentsImg from "../images/disrupting-investments.jpg"
import GlobalPartners1Img from "../images/global-partners1.jpg"
import GlobalPartners2Img from "../images/global-partners2.jpg"
import OperationsImg from "../images/operations.jpg"
import ConstructionImg from "../images/construction.jpg"
import hospitality from "../images/hospitality.jpg"
import retail from "../images/retail.jpg"
import DevelopmentImg from "../images/development.jpg"
import Globe from "../images/globe.svg"
import ServiceBlock from "../components/service-block"
import { PROJECTS } from "../config"
import Slider from "react-slick"
import ArrowLeft from "../icons/chevron-left.svg"
import ArrowRight from "../icons/chevron-right.svg"

const PROJECT_SLIDER_SETTINGS = {
  dots: false,
  infinite: true,
  speed: 300,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipeToSlide: true,
  className: "project-slide",
  nextArrow: null,
  prevArrow: null,
}

function Project({ title, subtitle, description, gallery = [] }) {
  const ref = useRef(null)

  return (
    <div key={title} className="project">
      <div>
        <div className="project-slider">
          <Slider {...PROJECT_SLIDER_SETTINGS} ref={ref}>
            {gallery.map((image, index) => (
              <img src={image} alt={title} key={index} />
            ))}
          </Slider>
          {gallery.length > 1 && (
            <div className="project-slider-nav">
              <button
                className="project-slider-nav-button prev"
                onClick={() => ref.current.slickPrev()}
              >
                <ArrowLeft />
              </button>
              <button
                className="project-slider-nav-button next"
                onClick={() => ref.current.slickNext()}
              >
                <ArrowRight />
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="project-info">
        <h3 className="project-title">{title}</h3>
        <h4 className="project-subtitle">{subtitle}</h4>
        <p className="project-description">{description}</p>
      </div>
    </div>
  )
}

function IndexPage() {
  return (
    <Layout>
      <SEO title="Home" />
      <div className="banner-section">
        <div className="banner-block">
          <h1 className="banner-text">Investments in Residential Property</h1>
          <div className="banner-image"></div>
        </div>
      </div>

      <div className="key-focus" id="about">
        <Feature
          title={"Disrupting Property Investment"}
          className="disrupting-investments"
          image={DisruptingInvestmentsImg}
          horizontalOnMobile={true}
          description="Led by international property entrepreneur, Pantazis Therianos, Pisach is a 'dynamic disrupter' to the property investment arena across all aspects of development, construction, serviced-accommodation, residential and hotels.serviced-accommodation, residential and hotels."
        />
      </div>

      <div id="global-partners" className="global-partner-section">
        <h2 className="section-title">
          London <br />
          Partners
        </h2>

        <Feature
          className="inner-section"
          image={GlobalPartners1Img}
          description="Pisach is a truly borderless and property business providing a range of property investment innovations to build a diverse and hard-working property portfolio."
        />

        <Feature
          className="inner-section"
          image={GlobalPartners2Img}
          description="We work with a variety of partners to deliver a range of property services and collaborate with a broad network of developers and property owners, who seek our expertise in supporting them to develop real estate."
        />
      </div>

      <div className="approach-section">
        <p>
          With a bespoke approach to property investment, Pisach's ethos is
          centred on unlocking the unlimited potential in your property
          investments. Our proven track record, demonstrates a commitment to
          finding innovative solutions to give our partners the best solutions.
        </p>
      </div>

      <div id="what-we-do" className="what-we-do">
        <ServiceBlock bgColor="#141536">
          <div className="title-block">
            <img src={Globe} alt="what-we-do" />
            <h2 className="title">What we do</h2>
          </div>
        </ServiceBlock>
        <ServiceBlock bgImage={DevelopmentImg}>
          <div className="title-block">
            <h2 className="title">Development</h2>
          </div>
        </ServiceBlock>
        <ServiceBlock bgImage={OperationsImg}>
          <div className="title-block">
            <h2 className="title">Operations</h2>
          </div>
        </ServiceBlock>
        <ServiceBlock bgImage={ConstructionImg}>
          <div className="title-block">
            <h2 className="title">Construction</h2>
          </div>
        </ServiceBlock>
        <ServiceBlock bgImage={hospitality}>
          <div className="title-block">
            <h2 className="title">Hospitality</h2>
          </div>
        </ServiceBlock>
        <ServiceBlock bgImage={retail}>
          <div className="title-block">
            <h2 className="title">Retail</h2>
          </div>
        </ServiceBlock>
      </div>

      <div className="projects-section">
        <h2 className="title">Projects</h2>
        <div>
          {PROJECTS.map(project => (
            <Project key={project.title} {...project} />
          ))}
        </div>
      </div>

      {/* <div id="about" className="about-section">
    <div className="ceo-image"></div>

    <h2 className="title">About</h2>
    <div className="description">
      <p>
        Pantazis Therianos is CEO of Euroterra Capital, Crystal Waters Global
        and Art Residences, a portfolio of property investment and development
        companies, operating across Europe and Asia, headquartered in Prime
        Central London with additional offices in Hong Kong, Beijing and
        Shanghai.{" "}
      </p>

      <p>
        With degrees in Real Estate Investment and in Civil Engineering from
        Cass Business School, Pantazis is a key figurehead in real estate and
        is often invited to be a guest speaker at industry events and
        conferences. He has recently spoken at The China Daily's Greater Bay
        Area (GBA) Conference in Hong Kong, where he delivered his views on
        development opportunities in the GBA, panel discussions at LSE Real
        Estate Club and the University of Cambridge, where he shared his view
        on the property industry and answered questions from the real estate
        students, and The Forum of the Hellenic-Chinese Cities.{" "}
      </p>

      <p>
        Outside of work, Pantazis is committed to improving the life of
        others, especially through charitable foundation Life & Smile; a
        charity in Greece that supports single parent immigrants trapped by
        poverty, to create a brighter more stable future.{" "}
      </p>
    </div>
  </div> */}
      {/* <div className="separator"></div> */}
    </Layout>
  )
}

export default IndexPage
